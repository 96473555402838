@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&family=Roboto&family=Source+Sans+Pro&display=swap');

body {
  margin: 0;
  background-color: #f7f7f7;
  color: black;
  font-family: Source Sans Pro,Avenir,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
   
}

main { 
  max-width: 1200px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bttn {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  cursor: pointer;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 15px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #f36d21;
  padding: 12px 13px 10px;
  color: #fff;
  font-size: 18px;
  margin-right: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

h1 {
  font-size: 48px;
    padding: 0;
    padding-top: 70px;
    margin-bottom: 20px;
}

h2 {
  font-size: 40px;
    padding-top: 0;
    padding-bottom: 30px;
}

.container {
    margin: 5px;
    padding: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    margin-bottom: 10px;
    text-align: left;
}

.tags {
  display: flex;
  flex-direction: row;
}

.tags > div {
  color: #3c3c3c;
    background-color: #f7f7f7;
    border: 1px solid #f7f7f7;
    padding: 3px 15px;
    border-radius: 3px;
    margin: 10px 15px 10px 0;
}

.name {
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 16px;
    font-size: 32px;
    margin-top: 0;
}

.client {
    min-height: 2rem;
    margin-bottom: 3px;
    margin-left: 1px;
    color: #9c9c9c;
}

.description {
  margin-top: 0;
  margin-bottom: 0.9rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.4px;
  min-height: 90px;
  font-size: 18px;
  min-height: 120px;
}

.link {
    color: #000;
    text-decoration: underline;
    padding: 10px 0 0 12px
}

.container-instructions {
    background-color: #fff;
    width: 1200px;
    max-width: 100vw;
    border-radius: 5px;
}

.system {
    font-weight: 800;
    text-decoration: none;
    line-height: 1.3em;
    cursor: pointer;
}
.it-container {
    padding: 15px 15px 0 15px;
    text-align: left;
}

.expand-icon {
  padding: 3px;
  margin-top: -65px;
  margin-left: -31px;
  background-color: #f36d21;
  color: white;
  cursor: pointer;
  transition: all 0.5s ease-out;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
